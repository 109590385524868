<template>
  <base-button
    class="group max-w-full center px-2 py-1 i-flex duration"
    :class="brandClasses.container"
    data-test-id="vf-pill"
  >
    <span v-if="$slots.before" class="mr-2">
      <slot name="before" />
    </span>
    <span class="text-xs c-grey-20" :class="{ truncate: 'truncate' }" data-test-id="vf-pill-text">
      <slot />
    </span>
    <span v-if="$slots.after" class="text-xs">
      <slot name="after" />
    </span>
    <span
      v-if="removable"
      :class="{ relative: removeIconSecondary }"
      data-test-id="vf-pill-close"
      style="margin: 0 0 0 0.5rem"
    >
      <vf-icon :class="{ 'absolute group-hocus:op-0': removeIconSecondary }" :name="removeIcon" />
      <vf-icon v-if="removeIconSecondary" class="op-0 group-hocus:op-100" :name="removeIconSecondary" />
    </span>
  </base-button>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
  /**
   * Defines whether close icon is shown or not
   */
    removable?: boolean
    /**
     * Defines whether we truncate pill's content.
     */
    truncate?: boolean
  }>(),
  {
    truncate: true
  }
)

const { brandClasses, removeIcon, removeIconSecondary } = useAppConfig().components.vf.pill
</script>
