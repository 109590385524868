<template>
  <component
    :is="(to && !$attrs.disabled) ? BaseLink : BaseButton"
    class="vf-button"
    :class="classes"
    data-test-id="vf-button"
    :disabled="!to ? ($attrs.disabled || loading) : null"
    :style="theme ? `--theme: ${theme}` : null"
    :to="!$attrs.disabled ? to : null"
  >
    <transition name="fade">
      <vf-loader v-if="loading" class="absolute" style="height: 3.33rem" />
      <vf-icon v-else-if="success && !loading" class="absolute" name="success" size="lg" />
    </transition>
    <span
      class="center text-center i-flex"
      :class="[{ 'op-0': loading || success }, classContent]"
    >
      <slot />
    </span>
  </component>
</template>

<script lang="ts" setup>
import { BaseButton, BaseLink } from '#components'
import { replaceValues } from '#core/utils/object/replaceValues'
import type { CSSClass, Responsive } from '#types/common'
import type { ButtonSize, ButtonVariant } from '#types/components/vf/button'

const props = withDefaults(defineProps<{
  /**
   * Style class for button content
   */
  classContent?: CSSClass
  /**
   * Disables the success icon after loading
   */
  disableSuccessIcon?: boolean
  /**
   * Indicates loading state
   */
  loading?: boolean
  /**
   * The size of the button which determines padding and font size
   * can be specified for different screen sizes
   */
  size?: ButtonSize | Partial<Responsive<ButtonSize>>
  theme?: string
  /**
   * The same as "to" prop of router-link, turns button into <a> with no change to styling
   */
  to?: string
  /**
   * Variant of the button which provides color, background, border
   * can be specified for different screen sizes
   */
  variant?: ButtonVariant | Partial<Responsive<ButtonVariant>>
}>(), {
  size: () => ({ sm: 'sm' }),
  variant: () => ({ sm: 'primary' })
})

// Some brands won't have certain variants, so we need to replace them with a fallback value
const { fallbackSizes = {}, fallbackVariants = {}, darkVariants = {} } = useAppConfig().components.vf.button

const success = ref(false)

const classes = computed(() => [
  getResponsiveClasses(replaceValues(props.variant, fallbackVariants), 'vf-button'),
  getResponsiveClasses(replaceValues(props.size, fallbackSizes), 'vf-button'),
  getResponsiveClasses(replaceValues(props.variant, darkVariants), 'dark:vf-button'),
  { relative: success.value || props.loading }
])

whenever(() => !props.loading && !props.disableSuccessIcon, () => {
  success.value = true
  setTimeout(() => {
    success.value = false
  }, 750)
})
</script>
