<template>
  <component
    :is="to ? BaseLink : BaseButton"
    :class="[
      brandClasses.link,
      {
        [brandClasses.linkVariantDefault || '']: !variant,
        [brandClasses.linkVariantQuiet || '']: variant === 'quiet',
        'text-sm lh-3': size === 'sm',
      },
    ]"
    data-test-id="vf-link"
    :to
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'
import { BaseButton, BaseLink } from '#components'
import type { LinkVariant } from '#types/components/vf/link'

defineProps<{
  to?: RouteLocationRaw
  variant?: LinkVariant
  size?: 'sm'
}>()

const { brandClasses } = useAppConfig().components.vf.link
</script>
