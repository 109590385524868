<template>
  <nav :aria-label="$t.breadcrumbs" :class="brandClasses.wrapper" data-test-id="vf-breadcrumbs">
    <slot name="firstStep">
      <template v-if="!maxItems || breadcrumbs.length < maxItems">
        <base-link
          v-if="showFirstStepIcon"
          :aria-label="breadcrumbs.length > 1 ? items[breadcrumbs.length - 2].label : firstStepLabel"
          class="-ml-2 lg:hidden c-grey-10"
          :to="breadcrumbs.length > 1 ? items[breadcrumbs.length - 2].url : (firstStep?.url || homeLink)"
        >
          <vf-icon dir="left" name="chevron" :size="iconSize" />
        </base-link>
        <vf-link
          :class="[brandClasses.firstStep, { '<lg:hidden': showFirstStepIcon }]"
          :to="firstStep?.url || homeLink"
          :variant="linkVariant"
        >
          {{ firstStepLabel }}
        </vf-link>
        <span
          :class="[
            brandClasses.separator,
            { '<lg:hidden': showFirstStepIcon, 'hidden': !breadcrumbs.length && !lastStep },
          ]"
        >
          <slot name="separator">
            <vf-icon v-if="separatorIcon" :name="separatorIcon" size="xs" />
            <template v-else>/</template>
          </slot>
        </span>
      </template>
    </slot>
    <template v-for="({ label, url }, i) of items" :key="i">
      <vf-link
        :class="[brandClasses.item, { '<lg:hidden': i < breadcrumbs.length - 1 }]"
        :to="url"
        :variant="linkVariant"
      >
        {{ label }}
      </vf-link>
      <span
        v-if="lastStep || i < breadcrumbs.length - 1"
        :key="`${i}-separator`"
        :class="[brandClasses.separator, { '<lg:hidden': i < breadcrumbs.length - 1 }]"
      >
        <slot name="separator">
          <vf-icon v-if="separatorIcon" :name="separatorIcon" size="xs" />
          <template v-else>/</template>
        </slot>
      </span>
    </template>
    <template v-if="lastStep">
      <span :class="brandClasses.lastStep">
        {{ lastStep.label }}
      </span>
    </template>
  </nav>
</template>

<script lang="ts" setup>
import type { Breadcrumb } from '#types/components/vf/breadcrumbs'

const props = withDefaults(
  defineProps<{
  /**
   * List of breadcrumb items
   */
    items: Breadcrumb[]
    /**
     * Data for first breadcrumb item
     */
    firstStep?: Breadcrumb
    /**
     * Data for last breadcrumb item
     */
    lastStep?: Breadcrumb
    /**
     * Maximum number of breadcrumb items
     */
    maxItems?: number
  }>(),
  {
    items: () => []
  }
)

const {
  brandClasses,
  homeLink,
  iconSize,
  linkVariant,
  separatorIcon,
  showFirstStepIcon
} = useAppConfig().components.vf.breadcrumbs
const { $t } = useNuxtApp()

const breadcrumbs = props.maxItems ? props.items.slice(props.maxItems * -1) : props.items
const firstStepLabel = props.firstStep?.label || $t.breadcrumbsHome
</script>
