<template>
  <base-toggle
    v-bind="{ value, disabled }"
    v-model="modelValue"
    data-test-id="vf-toggle-token"
    @change="$emit('change', $event)"
  >
    <span
      :class="[
        brandClasses.base,
        disabled ? brandClasses.disabled : selected ? brandClasses.selected : brandClasses.unselected,
      ]"
    >
      <slot />
    </span>
  </base-toggle>
</template>

<script lang="ts" setup>
const props = defineProps<{
  value?: string
  disabled?: boolean
}>()

defineEmits<{ change: [value: boolean] }>()

const { brandClasses } = useAppConfig().components.vf.toggleToken

const modelValue = defineModel<boolean | string[]>()

const selected = computed(() =>
  Array.isArray(modelValue.value) && props.value ? modelValue.value.includes(props.value) : modelValue.value
)
</script>
