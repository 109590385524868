<template>
  <p :class="[brandClasses.text, variant ? brandClasses.errorVariant : brandClasses.error]">
    <slot />
  </p>
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Defines variant
   */
  variant?: 'inverse'
}>()

const { brandClasses } = useAppConfig().components.vf.form.error
</script>
