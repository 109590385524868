<template>
  <div class="relative overflow-hidden">
    <slot v-if="showArrows" name="prev" v-bind="{ active: carousel.canScrollPrev, action: scrollPrev }">
      <base-button
        :aria-label="$t.previous"
        class="absolute z-10 flex duration "
        :class="[
          (!loop || !(carousel.canScrollNext || carousel.canScrollPrev))
            && !carousel.canScrollPrev && 'pointer-events-none !op-0 invisible',
          dir ? 'inset-x-0 justify-center my-4 top-0' : 'top-1/2 transform -translate-y-1/2 left-0',
          classControls,
          {
            'op-100 ': showArrowsOnHoverOnly && !isOutside
              && (!showArrowsOnHoverThreshold || mouseX < elementWidth * showArrowsOnHoverThreshold),
            'op-0 focus-visible:op-100 ': showArrowsOnHoverOnly,
          },
        ]"
        data-test-id="vf-carousel-arrow-left"
        :style="styleControls"
        @click.stop="scrollPrev"
      >
        <vf-icon :dir="dir ? 'up' : 'left'" name="chevron" :size="sizeControls" />
      </base-button>
    </slot>

    <slot v-if="showArrows" name="next" v-bind="{ active: carousel.canScrollNext, action: scrollNext }">
      <base-button
        :aria-label="$t.next"
        class="absolute z-10 flex duration "
        :class="[
          (!loop || !(carousel.canScrollNext || carousel.canScrollPrev))
            && !carousel.canScrollNext && 'pointer-events-none !op-0 invisible',
          dir ? 'inset-x-0 justify-center my-4 bottom-0' : 'top-1/2 transform -translate-y-1/2 right-0',
          classControls,
          {
            'op-100 ': showArrowsOnHoverOnly && !isOutside
              && (!showArrowsOnHoverThreshold || mouseX > elementWidth * (1 - showArrowsOnHoverThreshold)),
            'op-0 focus-visible:op-100 ': showArrowsOnHoverOnly,
          },
        ]"
        data-test-id="vf-carousel-arrow-right"
        :style="styleControls"
        @click.stop="scrollNext"
      >
        <vf-icon :dir="dir ? 'down' : 'right'" name="chevron" :size="sizeControls" />
      </base-button>
    </slot>

    <slot
      v-if="autoplay && showProgress"
      name="progressbar"
      v-bind="{ progress: intervalProgress, currentSlide, slides: carousel.slides }"
    >
      <div class="absolute right-0 z-10 flex p-4 pt-2 <lg:top-0 lg:bottom-0 lg:p-6">
        <vf-video-controls
          v-for="(_, i) in carousel.slides"
          :key="i"
          :active="currentSlide.index === i"
          :aria-label="progressButtonAriaLabel(i)"
          :inactive-label="String(i + 1)"
          :paused="interval && !interval.isActive"
          :progress="intervalProgress"
          size="sm"
          @click="currentSlide.index === i ? togglePlay() : scrollTo(i, true)"
        />
      </div>
    </slot>

    <div ref="emblaRef">
      <div ref="carouselContainer" class="flex children:shrink-0" :class="classContainer" :style="styleContainer">
        <slot name="default" v-bind="{ paused: interval && !interval.isActive }" />
      </div>
    </div>

    <slot v-if="showPagination" name="pagination" v-bind="{ activeItem: currentSlide.index }" />
  </div>
</template>

<script setup lang="ts">
import emblaCarouselVue from 'embla-carousel-vue'
import type { StyleValue } from 'vue'
import type { EmblaCarouselType, EmblaEventType } from 'embla-carousel'
import type { CSSClass } from '#types/common'
import type { IconSizes } from '#types/sizes'

type AlignmentType = 'start' | 'center' | 'end'

const props = withDefaults(defineProps<{
  /**
   * Defines active.
   * If true carousel will be able to scroll
   */
  active?: boolean
  /**
   * Defines autoplay mode.
   * If true slides change automatically
   */
  autoplay?: boolean
  /**
   * Defines classList of items container
   */
  classContainer?: CSSClass
  /**
   * Defines classList of controls
   */
  classControls?: CSSClass
  /**
   * Defines classList of all content's wrapper excluding pagination section
   */
  classWrapper?: CSSClass
  /**
   * Defines carousel direction
   */
  dir?: 'vertical'
  /**
   * Allows the drag to be free instead instead of snaping into position
   */
  dragFree?: boolean
  /**
   * Defines highlights center to scale up/down the content and change its opacity
   */
  highlightCenter?: boolean
  /**
   * Defines interval for slides changing
   * Relevant if `autoplay` prop provided only
   */
  interval?: number
  /**
   * Defines arrows visibility
   * If true arrows become always visible and that makes possible to scroll infinitely
   */
  loop?: boolean
  /**
   * Defines pagination visibility
   */
  showPagination?: boolean
  /**
   * Defines arrow visibility
   */
  showArrows?: boolean
  /**
   * Defines arrow visibility only on hover
   */
  showArrowsOnHoverOnly?: boolean
  /**
   * Defines percentage of element width that triggers arrow visibility on hover
   */
  showArrowsOnHoverThreshold?: number
  /**
   * Defines progress visibility
   */
  showProgress?: boolean
  /**
   * Defines how many slides will be scrollable when the scroll is called
   */
  slidesToScroll?: number | 'auto'
  /**
   * Defines styles of items container
   */
  styleContainer?: StyleValue
  /**
   * Defines styles of all content's wrapper excluding pagination section
   */
  styleWrapper?: StyleValue
  /**
   * Defines styles of controls container
   */
  styleControls?: StyleValue
  /**
   * Defines size of controls icon
   */
  sizeControls?: IconSizes
  /**
   * Defines HTML tag of items container
   */
  tag?: string
  /**
   * Defines the alignment of slides relative to the carousel viewport
   */
  align?: AlignmentType
}>(), {
  active: true,
  showPagination: true,
  showArrows: true,
  showArrowsOnHoverThreshold: 0,
  slidesToScroll: 'auto',
  sizeControls: 'lg',
  align: 'center'
})

const emblaOptions = ref({
  active: props.active,
  align: props.align,
  dragFree: props.dragFree,
  inViewThreshold: 0.3,
  loop: props.loop,
  slidesToScroll: props.slidesToScroll
})

const [emblaRef, emblaApi] = emblaCarouselVue(emblaOptions)

const { elementWidth, isOutside, x: mouseX } = useMouseInElement(emblaRef)
const { $t } = useNuxtApp()

const interval = ref()
const intervalProgress = ref(0)

const carouselContainer = ref()

const carousel = reactive({
  canScrollNext: false,
  canScrollPrev: false,
  containerWidth: 0,
  slides: 0
})

watchEffect(() => {
  if (carouselContainer.value)
    carousel.containerWidth = carouselContainer.value.scrollWidth
})

const currentSlide = reactive({
  index: 0,
  interval: 0
})

const TWEEN_FACTOR_BASE = 0.52
let tweenFactor = 0
let tweenNodes: HTMLElement[] = []

const ticker = 100

const progressButtonAriaLabel = (slideIndex) => currentSlide.index === slideIndex
  ? interval.value.isActive ? $t.videoPause : $t.videoPlay
  : replaceAll($t.goToSlide, { slide: (slideIndex + 1) })

const scrollNext = () => emblaApi.value?.scrollNext()
const scrollPrev = () => emblaApi.value?.scrollPrev()
const scrollTo = (index, jump?: boolean) => {
  emblaApi.value?.scrollTo(index, jump)
  currentSlide.index = index
}

const setTweenFactor = (emblaApi: EmblaCarouselType) => {
  tweenFactor = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
}

const setTweenNodes = (emblaApi: EmblaCarouselType) => {
  tweenNodes = emblaApi.slideNodes().map((slideNode) => slideNode.firstChild as HTMLElement)
}

const tweenScale = (emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
  const engine = emblaApi.internalEngine()
  const scrollProgress = emblaApi.scrollProgress()
  const slidesInView = emblaApi.slidesInView()
  const isScrollEvent = eventName === 'scroll'

  emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
    let diffToTarget = scrollSnap - scrollProgress
    const slidesInSnap = engine.slideRegistry[snapIndex]

    slidesInSnap.forEach((slideIndex) => {
      if (isScrollEvent && !slidesInView.includes(slideIndex)) return

      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target()

          if (slideIndex === loopItem.index && target !== 0) {
            const sign = Math.sign(target)

            if (sign === -1)
              diffToTarget = scrollSnap - (1 + scrollProgress)

            if (sign === 1)
              diffToTarget = scrollSnap + (1 - scrollProgress)
          }
        })
      }

      const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor)
      const scale = Math.min(Math.max(tweenValue, 0), 1).toString()
      const tweenNode = tweenNodes[slideIndex]
      tweenNode.style.transform = `scale(${scale})`
      tweenNode.style.opacity = scale
    })
  })
}

const setupTweenScale = (emblaApi: EmblaCarouselType) => {
  setTweenNodes(emblaApi)
  setTweenFactor(emblaApi)
  tweenScale(emblaApi)

  emblaApi
    .on('reInit', setTweenNodes)
    .on('reInit', setTweenFactor)
    .on('reInit', tweenScale)
    .on('scroll', tweenScale)
    .on('slideFocus', tweenScale)
}

const togglePlay = () => {
  if (!interval.value) return
  interval.value.isActive ? interval.value.pause() : interval.value.resume()
}

const updateCurrentSlide = (emblaApi: EmblaCarouselType) => {
  carousel.canScrollNext = emblaApi.canScrollNext()
  carousel.canScrollPrev = emblaApi.canScrollPrev()
  carousel.slides = emblaApi.slideNodes().length
  currentSlide.index = emblaApi.selectedScrollSnap()
  intervalProgress.value = 0

  const selectedElement = emblaApi.slideNodes()[currentSlide.index]

  currentSlide.interval = Number(selectedElement?.getAttribute('interval') || 0)
}

watch([() => props.dragFree, () => props.loop, () => props.slidesToScroll, () => props.active], () => {
  emblaOptions.value = {
    ...emblaOptions.value,
    dragFree: props.dragFree,
    loop: props.loop,
    slidesToScroll: props.slidesToScroll,
    active: props.active
  }
})

onMounted(() => {
  if (emblaApi?.value) {
    updateCurrentSlide(emblaApi.value)

    emblaApi.value.on('select', updateCurrentSlide)
    emblaApi.value.on('reInit', updateCurrentSlide)

    if (props.highlightCenter) setupTweenScale(emblaApi.value)
  }

  if (props.autoplay && props.interval) {
    interval.value = useIntervalFn(() => {
      intervalProgress.value += 1 / ((currentSlide.interval || props.interval || 5000) / ticker)

      if (intervalProgress.value >= 1) scrollNext()
    }, ticker)
  }
})

defineExpose({
  carousel,
  currentIndex: computed(() => currentSlide.index),
  scrollNext,
  scrollPrev,
  scrollTo,
})
</script>
