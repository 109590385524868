<template>
  <span
    :aria-label="label || $t.quantity"
    class="h-8 overflow-hidden b b-grey-40 rounded-sm i-flex "
    data-test-id="vf-stepper"
    role="group"
  >
    <base-button
      :aria-label="`${$t.decrease} ${label || $t.quantity}`"
      class="w-8 flex center duration"
      :class="{ 'bg-grey-90 c-grey-50': disabled || model <= min }"
      :disabled="disabled || model <= min"
      @click="model -= step"
    >
      <slot name="decrease-icon" :size="iconSize">
        <vf-icon name="minus" :size="iconSize" />
      </slot>
    </base-button>
    <span
      aria-atomic="true"
      aria-live="polite"
      class="w-10 flex center b-x b-grey-40 text-md duration "
      :class="{ 'bg-grey-90 c-grey-50': disabled }"
    >
      {{ model }}
    </span>
    <base-button
      :aria-label="`${$t.increase} ${label || $t.quantity}`"
      class="w-8 flex center duration"
      :class="{ 'bg-grey-90 c-grey-50': disabled || model >= max }"
      :disabled="disabled || model >= max"
      @click="model += step"
    >
      <vf-icon name="plus" :size="iconSize" />
    </base-button>
  </span>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  /**
   * Minimum value inclusive
   */
  min?: number
  /**
   * Maximum value inclusive
   */
  max?: number
  /**
   * Step increase/decrease by pressing the buttons
   */
  step?: number
  /**
   * Defines disabled state
   */
  disabled?: boolean
  /**
   * Accessible label for stepper
   */
  label?: string
}>(), { min: 1, max: 100, step: 1 })

const model = defineModel<number>({ default: 1 })

const { iconSize } = useAppConfig().components.vf.stepper
</script>
