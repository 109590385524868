<template>
  <base-popover
    v-slot="props"
    :arrow-shift="-6"
    class="flex"
    data-test-id="vf-toggletip"
    :delay="delay"
    :flip="flip"
    :offset="{ mainAxis: 10, alignmentAxis: -20 }"
    :placement="placement"
    :teleport="teleport"
  >
    <base-button
      :aria-describedby="id"
      role="tooltip"
      v-bind="propsTrigger"
      @blur="props.close"
      @click.enter.stop="props.toggle"
      @keyup.esc.stop="props.close"
    >
      <span class="sr-only">{{ $t.moreInfoAbout }}</span>
      <!-- @slot Slot for toggletip trigger -->
      <slot name="trigger" v-bind="props" />
    </base-button>
    <base-popover-content
      :id
      aria-live="polite"
      class="z-toast break-words b b-grey-70 bg-white px-4 py-6 text-sm shadow-xs transition"
      data-test-id="vf-toggletip-content"
      enter-active-class="transform ease-out"
      enter-from-class="op-0 scale-95"
      enter-to-class="translate-y-0 scale-100"
      leave-active-class="transform ease-in"
      leave-from-class="translate-y-0 scale-100"
      leave-to-class="op-0 scale-95"
      role="tooltip"
      style="max-width: 15rem"
    >
      <!-- @slot Slot for toggletip content -->
      <slot v-bind="props" />
      <base-popover-arrow
        class="absolute transform b-grey-70 bg-white"
        :class="props.side === 'top' ? 'b-b b-r' : 'b-t b-l'"
        style="width: 0.625rem; height: 0.625rem; --un-rotate: 45deg"
      />
    </base-popover-content>
  </base-popover>
</template>

<script lang="ts" setup>
withDefaults(defineProps<{
  /**
   * The position of the Tooltip.
   * By default `bottom`.
   */
  placement?: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end'
  /**
   * If tooltip should change the placement of the floating element to the opposite
   * on scroll/resize
   */
  flip?: boolean
  /**
   * Show/Hide delay, or object in ms.
   */
  delay?: number | { show?: number, hide?: number }
  /**
   * Where the Popover should be mounted to. By default it will be mounted to the body.
   */
  teleport?: boolean | string | HTMLElement
  /**
   * Attributes to be applied to trigger container.
   */
  propsTrigger?: Record<string, unknown>

}>(), {
  flip: true,
  teleport: true
})
const id = useId()
</script>
