<template>
  <base-popover-content
    class="z-above-header flex justify-end px-4 transition"
    :class="classContent"
    enter-active-class="transform ease-out"
    enter-from-class="op-0 -translate-y-8 will-change-transform"
    enter-to-class="translate-y-0"
    leave-active-class="transform ease-in"
    leave-from-class="translate-y-0"
    leave-to-class="op-0 -translate-y-8"
    :max-height-offset="maxHeightOffset"
    @keydown.esc="forceClose"
  >
    <div v-bind="$attrs" class="relative overflow-y-auto bg-white p-4 rounded-lg shadow-sm ">
      <base-button
        v-if="!closeOnLeave"
        :aria-label="$t.close"
        class="absolute right-0 top-0 m-4"
        @click="forceClose"
      >
        <span v-if="$slots.cancel">{{ $t.cancel }}</span>
        <vf-icon v-else name="close" size="md" />
      </base-button>
      <slot />
    </div>
  </base-popover-content>
</template>

<script lang="ts" setup>
import { PopoverContextKey } from '#core/components/base/popover/context'
import type { CSSClass } from '#types/common'

defineOptions({ inheritAttrs: false })

defineProps<{
  classContent?: CSSClass
  maxHeightOffset?: number
}>()

const { closeOnLeave, forceClose } = inject(PopoverContextKey)!

useRouter().beforeEach(forceClose)
</script>
