<template>
  <base-progress
    v-slot="{ percentage }"
    v-style="brandStyles?.container"
    v-bind="$props"
    class="overflow-hidden"
    :class="brandClasses.container"
    data-test-id="vf-progress-bar"
    role="presentation"
  >
    <div
      class="h-full"
      :class="brandClasses.progress"
      :style="`width: ${percentage}%`"
    />
  </base-progress>
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Defines maximum (total) value that can be achieved
   */
  max?: number | string
  /**
   * Defines current value
   */
  value?: number | string
}>()

const { brandClasses, brandStyles } = useAppConfig().components.vf.progressBar
</script>
