<template>
  <div class="relative f-col text-sm space-y-1 " data-test-id="article-card">
    <div class="relative after:block after:pt-full after:content-empty">
      <base-picture
        :alt="image.alt"
        class="absolute-0 mb-2 full "
        fit="cover"
        height="340"
        :src="image.src"
        width="340"
      />
    </div>

    <p v-if="tags" class="line-clamp-1 eyebrow <md:hidden space-x-4 ">
      <span v-for="tag in tags" :key="tag">
        {{ tag }}
      </span>
    </p>
    <base-link :class="brandClasses.title" :to>
      {{ title }}
      <span class="absolute-0" />
    </base-link>
    <p v-if="showDate && !hidePageDate && date" class="text-xs ">
      {{ date }}
    </p>
    <p v-if="description" :class="brandClasses.description">
      {{ description }}
    </p>
    <p class="f-col grow justify-end underlined">
      {{ $t.readMore }}
    </p>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  title: string
  to: string
  image: {
    src: string
    alt?: string
  }
  tags?: string[]
  date?: string
  description?: string
  hidePageDate?: boolean
}>()

const { showDate, brandClasses } = useAppConfig().components.vf.articleCard
</script>
