<template>
  <div class="flex" style="min-height: 5rem">
    <base-progress
      v-slot="{ percentage }"
      class="w-full rounded-full bg-grey-60"
      data-test-id="vf-milestone-bar"
      :max
      role="presentation"
      :style="{ [`margin-${labelPosition}`]: 'auto', height: '0.3125rem' }"
      :value="value - offset"
    >
      <div class="relative h-full">
        <div
          v-for="({ label, count, text }) in milestones"
          :key="count"
          class="z-2 rounded-full center-absolute"
          :class="value >= count ? brandClasses.milestoneFull : brandClasses.milestoneEmpty"
          :style="{
            left: `${((count - offset) * 100) / max}%`,
            borderWidth: '0.3125rem',
            width: '1.25rem',
            height: '1.25rem',
          }"
        >
          <div
            class="absolute transform text-center"
            :style="{
              'left': '50%',
              '--un-translate-x': '-50%',
              [indentDirection[labelPosition]]: '1.5rem',
            }"
          >
            <div class="fw-bold">
              {{ label }}
            </div>
            <div>{{ text }}</div>
          </div>
        </div>
        <div class="absolute full rounded-full" :class="brandClasses.milestoneBar" :style="{ width: `${percentage}%` }" />
      </div>
    </base-progress>
  </div>
</template>

<script setup lang="ts">
import type { PositionsSubUnion } from '#types/positions'

type Milestone = {
  label?: string
  count: number
  text?: string
}

const props = withDefaults(defineProps<{
  value: number
  milestones: Milestone[]
  labelPosition?: PositionsSubUnion<'top' | 'bottom'>
  scaleFactor?: number | 'evenly'
  offset?: number
}>(), {
  value: 0,
  labelPosition: 'bottom',
  scaleFactor: 'evenly',
  offset: 0
})

const { brandClasses } = useAppConfig().components.vf.milestoneBar

const indentDirection = {
  top: 'bottom',
  bottom: 'top'
}

const maxStepValue = computed(() => Math.max(...props.milestones.map(({ count }) => count - props.offset)))
const max = computed(() => {
  const increase = props.scaleFactor === 'evenly' ? maxStepValue.value / props.milestones.length : props.scaleFactor * maxStepValue.value / 100
  return maxStepValue.value + increase
})
</script>
