<template>
  <base-button
    class="w-full"
    data-test-id="vf-zoomer"
    @blur="resetZoom"
    @click="toggleZoom"
    @keydown.down.prevent="mouseY = Math.min(mouseY + 50, h)"
    @keydown.enter="sourceType = null"
    @keydown.esc="resetZoom"
    @keydown.left.prevent="mouseX = Math.max(0, mouseX - 50)"
    @keydown.right.prevent="mouseX = Math.min(mouseX + 50, w)"
    @keydown.space="sourceType = null"
    @keydown.up.prevent="mouseY = Math.max(0, mouseY - 50)"
    @mouseleave="resetZoom"
  >
    <span class="sr-only">{{ isZoomed ? $t.zoomOut : $t.zoomIn }} - </span>
    <base-picture
      ref="target"
      v-bind="{ src, alt, height, width }"
      class="w-full"
      :props-img="{
        ...propsImg,
        class: ['duration', propsImg?.class, { 'op-0': isZoomed }],
        style: { transitionDelay: isZoomed ? undefined : '200ms' },
      }"
      style="transition: background-size 200ms linear, opacity 200ms 200ms"
      :style="[
        {
          '--bg': `url(${_srcZoom})`,
          'background': `var(--bg) no-repeat ${x} ${y} / ${isZoomed ? 200 : 100}%`,
          'cursor': isZoomed ? 'zoom-out' : 'zoom-in',
          'touch-action': isZoomed ? 'none' : undefined,
        },
      ]"
    />
  </base-button>
</template>

<script lang="ts" setup>
import type { Position } from '@vueuse/core'
import type { Responsive } from '#types/common'
import type { ImgHTMLAttributes } from '#types/components/base/picture'

const props = defineProps<{
  /**
   * Source of the main image
   */
  src: Responsive | string
  /**
   * Source of the zoomed image to be displayed on hover
   */
  srcZoom: Responsive | string
  /**
   * Specifies width for various screen sizes
   */
  width: Responsive | string | number
  /**
   * Specifies height for various screen sizes
   */
  height: Responsive | string | number
  /**
   * Alt text for main image
   */
  alt?: string
  /**
   * Additional props for internal img tag
   */
  propsImg?: ImgHTMLAttributes
}>()

const emit = defineEmits<{
  zoom: [zoomFactor: number]
  swipe: [direction: number]
}>()

const isZoomed = ref(false)
const target = ref()

const _srcZoom = computed(() =>
  isObject(props.srcZoom) ? (props.srcZoom.lg || props.srcZoom.md || props.srcZoom.sm) : props.srcZoom
)

const {
  elementX: mouseX,
  elementY: mouseY,
  elementWidth: w,
  elementHeight: h,
  sourceType
} = useMouseInElement(target, { handleOutside: false })

const drag = reactive({
  startTime: 0,
  startX: 0,
  startY: 0,
  x: 0,
  y: 0,

})

const { y: offsetY } = useElementBounding(target)

const resetZoom = () => isZoomed.value = false
const getVelocity = useDebounceFn((distance) => {
  const endTime = new Date().getTime()
  const time = endTime - drag.startTime // Time in milliseconds

  return distance / time // Velocity in pixels per millisecond
}, 100)

const { isDragging } = useDraggable(() => target.value?.picture, {
  preventDefault: true,
  onStart: (_, event: PointerEvent) => {
    if (isZoomed.value && event.pointerType === 'touch')
      drag.startTime = new Date().getTime()
  },
  onEnd: (_, event: PointerEvent) => {
    if (isZoomed.value && event.pointerType === 'touch') {
      drag.startX = drag.x
      drag.startY = drag.y
    }
  },
  onMove: (position: Position, event: PointerEvent) => {
    if (isZoomed.value && event.pointerType === 'touch') {
      getVelocity(position.x).then((velocity) => {
        const velocityThreshold = 0.7 // Velocity in pixels per millisecond

        if (Math.abs(velocity) > velocityThreshold)
          emit('swipe', Math.sign(position.x)) // Negative position.x LEFT; Positive position.x RIGHT
      })

      drag.x = Math.max(0, Math.min(drag.startX - position.x, w.value))
      drag.y = Math.max(0, Math.min(drag.startY - (position.y - offsetY.value), h.value))
    }
  }
})

const x = computed(
  () => `${((sourceType.value !== 'touch' && !isDragging.value ? mouseX.value : drag.x) / w.value) * 100}%`
)
const y = computed(
  () => `${((sourceType.value !== 'touch' && !isDragging.value ? mouseY.value : drag.y) / h.value) * 100}%`
)

const toggleZoom = () => {
  isZoomed.value = !isZoomed.value
  emit('zoom', isZoomed.value ? 1 : 0)

  if (isZoomed.value) {
    drag.startX = w.value / 2
    drag.startY = h.value / 2
    drag.x = w.value / 2
    drag.y = h.value / 2
  }
}

defineExpose({ resetZoom })
</script>
