<template>
  <base-tabs-root v-model="activeTab" data-test-id="vf-tabs">
    <base-tabs-list class="overflow-y-auto scrollbar-none">
      <div
        :class="[
          brandClasses[triggerVariant].triggerContainer,
          variant ? 'justify-start gap-6' : 'justify-center',
        ]"
      >
        <base-tabs-trigger
          v-for="(tab, i) in tabs"
          :key="i"
          :class="[
            brandClasses[triggerVariant].trigger,
            variant ? 'px-1' : 'px-4',
            activeTab === `${i}` && brandClasses[triggerVariant].triggerActive,
          ]"
          data-test-id="base-button"
          :value="`${i}`"
          @click="$emit('change', activeTab)"
        >
          <slot :name="`trigger-${i}`">
            {{ tab }}
          </slot>
        </base-tabs-trigger>
      </div>
    </base-tabs-list>
    <base-tabs-content v-for="(tab, i) in tabs" :key="tab + i" as-child :value="`${i}`">
      <slot :name="`content-${i}`" />
    </base-tabs-content>
  </base-tabs-root>
</template>

<script lang="ts" setup>
const { triggerVariant } = withDefaults(defineProps<{
  /**
   * List of string that will be used as labels of tabs or to determine number of tabs
   */
  tabs: string[]
  /**
   * Defines whether tabs should be aligned to the left side
   * By default aligns to center
   */
  variant?: 'left'
  /**
   * Defines variant of the tabs triggers
   * primary - matches design system
   * secondary - optional for case of brand needing more than one tabs design
   * By default matches design system
   */
  triggerVariant?: 'primary' | 'secondary'
}>(), {
  triggerVariant: 'primary'
})

defineEmits<{
  change: [activeTab: string]
}>()

const activeTab = defineModel<string>('active', { default: '0' })

const { brandClasses } = useAppConfig().components.vf.tabs
</script>
