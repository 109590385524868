<template>
  <div
    class="relative md:w-80 $rounded $width "
    :class="[
      classToast,
      { '-mt-4': variant },
      { 'bg-white shadow-xl px-4 pb-6 pt-4 ': !variant },
    ]"
    data-test-id="vf-toast"
    style="--rounded: 0.125rem; --width: 23.5rem"
  >
    <template v-if="!variant">
      <div class="flex grow gap-x-2 wrap">
        <div class="w-full flex ">
          <vf-icon
            v-if="type || icon"
            class="shrink-0 "
            :class="{
              'c-green-30': type === 'success',
              'c-grey-10': type === 'info',
              'c-red-30': type === 'error',
              'self-end': icon,
            }"
            :name="type"
            :size="icon?.size || iconSize[$viewport.breakpoint]"
            :src="icon?.src"
          />
          <div
            v-if="title || message"
            class="ml-2 mt-2 grow text-sm "
            :class="classMessage"
            style="margin-right: 2.125rem"
          >
            <p v-if="title" class="mb-2 fw-bold ">
              <base-interpolator :content="title">
                <template #uppercase="{ args: [message] }">
                  <span class="uppercase">
                    {{ message }}
                  </span>
                </template>
              </base-interpolator>
            </p>
            <p v-if="message">
              <base-interpolator :content="message">
                <template #bold="{ args: [message] }">
                  <span class="fw-bold">
                    {{ message }}
                  </span>
                </template>
              </base-interpolator>
            </p>
          </div>
          <base-button
            :aria-label="$t.close"
            class="absolute right-0 top-0 mr-4 mt-4 p-1 "
            @click="$emit('close')"
          >
            <vf-icon name="close" size="md" />
          </base-button>
        </div>
        <component
          :is="isTextButton ? BaseButton : VfButton"
          v-for="({ isTextButton, label, to, click }, key) in ctas"
          :key
          v-bind="{ ...(isTextButton ? {} : { size: 'xs', variant: key ? 'secondary' : 'primary' }) }"
          class="mt-6 grow "
          :class="{ 'text-sm underlined ml-10': isTextButton }"
          :to
          @click="clickCta(click)"
        >
          {{ label }}
        </component>
      </div>
      <div v-if="bottomText" v-html="bottomText" class="mt-2 bg-grey-90 px-4 py-3 -mx-4 -mb-6" />
    </template>
    <template v-else>
      <Teleport to="body">
        <div class="absolute-0 z-overlay full" @click="$emit('close')" />
      </Teleport>
      <div
        class="relative pb-6 md:w-80 "
        data-test-id="vf-toast"
        @click="$emit('close')"
      >
        <div
          :class="classMessage"
          data-test-id="rewards-info-box"
          style="border-top-right-radius: 0; margin-top: -1px;"
        >
          <div class="text-md">
            {{ title }}
          </div>
          <div class="mt-4 flex">
            <vf-icon class="mr-4" :size="icon?.size" :src="icon?.src" />
            <div>
              <p class="fw-medium lh-none">
                {{ message }}
              </p>
              <p class="mt-2 ws-pre-wrap lh-tight">
                {{ bottomText }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { ToastProps } from '#types/notification'
import { BaseButton, VfButton } from '#components'

withDefaults(
  defineProps<ToastProps>(),
  {
    type: 'success'
  }
)

const emit = defineEmits<{
  close: []
}>()

const { iconSize } = useAppConfig().components.vf.toast

const clickCta = (ctaClickHandler?) => {
  ctaClickHandler?.()
  emit('close')
}
</script>
