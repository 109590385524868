<template>
  <component
    :is="to ? BaseLink : BaseButton"
    :aria-describedby="tileDescriptionId"
    :aria-labelledby="tileTitleId"
    class="w-full f-col items-center b-transparent px-4 pb-6 text-center duration "
    :class="[brandClasses.tile, { 'pointer-events-none': disabled }]"
    data-test-id="vf-tile"
    :disabled
    style="border-top-width: 3px; gap: 0.3125rem; padding-top: 1.3125rem"
    :to
  >
    <vf-icon :name="icon" size="2xl" />
    <span :id="tileTitleId" class="title-3 ">
      <slot name="title">
        {{ title }}
      </slot>
    </span>
    <span v-if="description" :id="tileDescriptionId" class="ws-pre-wrap text-sm c-black">
      <slot name="description">
        {{ description }}
      </slot>
    </span>
  </component>
</template>

<script lang="ts" setup>
import { BaseButton, BaseLink } from '#components'
import type { IconName } from '#types/icon'

defineProps<{
  icon: IconName
  title: string
  description?: string
  to?: string
  disabled?: boolean
}>()

const id = useId()
const { brandClasses } = useAppConfig().components.vf.tile
const tileTitleId = `tile-title-${id}`
const tileDescriptionId = `tile-desc-${id}`
</script>
