<template>
  <base-collapse
    v-model="modelValue"
    class="f-col"
    :class="[brandClasses.wrapper, { 'children:px-4': !variant }]"
    :class-content="classContent"
    :class-toggle="classToggle"
    data-test-id="vf-accordion"
    :value
  >
    <template #toggle="{ expanded }">
      <slot :expanded name="toggle">
        <span
          class="flex items-center between"
          :class="brandClasses.toggle[size]"
          data-test-id="vf-accordion-toggle"
        >
          <span :class="classTitle ?? brandClasses.title[size]">
            {{ title }}
          </span>
          <vf-icon v-if="iconCollapse" :name="expanded ? iconCollapse : icon" :size="iconSize[size]" />
          <vf-icon v-else :dir="expanded ? 'up' : 'down'" :name="icon" :size="iconSize[size]" />
        </span>
      </slot>
    </template>
    <template #default>
      <div :class="brandClasses.content" data-test-id="vf-accordion-content">
        <slot />
      </div>
    </template>
  </base-collapse>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'
import type { IconName } from '#types/icon'

withDefaults(
  defineProps<{
  /**
   * Text that will be rendered in top section if no toggle-slot passed
   */
    title?: string
    /**
     * Icon that will be rendered in top section if no toggle-slot passed
     */
    icon?: IconName
    /**
     * Icon that will be shown when accordion is expanded. If not provided then default chevron would be rotated.
     */
    iconCollapse?: IconName
    /**
     * If 'sm' selected then titles are smaller and less vertical space is used.
     */
    size?: 'sm' | 'md'
    /**
     * If 'aligned' selected then border and content
     * align to the title text and expand/collapse icon.
     */
    variant?: 'aligned'
    /**
     * Classes to be applied to content container.
     */
    classContent?: CSSClass
    /**
     * Classes to be applied to toggle button.
     */
    classToggle?: CSSClass
    /**
     * Optional title class to be applied to the accordion title
     */
    classTitle?: CSSClass
    /**
     * Allows for use within a group where only one accordion can be expanded at a time.
     * In this case, all accordions in the group should be bound to the same model value
     * and each individual accordion should have a unique `value`.
     */
    value?: string
  }>(),
  {
    icon: 'chevron',
    size: 'md'
  }
)

const modelValue = defineModel<boolean | string>('open')

const { brandClasses, iconSize } = useAppConfig().components.vf.accordion
</script>
