<template>
  <base-dialog-content
    :aria-describedby="undefined"
    data-test-id="vf-dialog-content"
    @interact-outside="interactOutside"
  >
    <slot />
  </base-dialog-content>
</template>

<script lang="ts" setup>
const props = defineProps<{
  disableOutsideInteraction?: boolean
}>()

defineSlots<{
  default: void
}>()

const { ignoreSelectors } = useAppConfig().components.vf.dialog
/**
 * ignoreSelectors are used to prevent closing modal when clicking on specific elements.
 * Used in cases when we have 2 modals opened at the same time (usually 3rd party + native) and we want to close only one of them.
 * @param event
 */
const interactOutside = (event) => {
  const target = event.target as HTMLElement
  if ((target && ignoreSelectors.some((selector) => target.closest(selector))) || props.disableOutsideInteraction)
    return event.preventDefault()
}
</script>
