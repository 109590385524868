<template>
  <div
    class="full f-col"
    :class="{ 'max-h-100dvh': isPanel }"
    data-id="dialog-layout"
    data-test-id="vf-dialog-layout"
  >
    <header
      v-show="!hideHeader"
      class="w-full flex items-center gap-2"
      :class="[{ 'b-b b-grey-70 p-4 lg:px-6': isPanel }, brandClasses[type]?.header]"
    >
      <slot name="back" />
      <base-dialog-title class="line-clamp-1 w-full">
        <slot name="title" />
      </base-dialog-title>
      <base-dialog-close
        v-if="!hideCloseButton"
        :aria-label="$t.close"
        :class="[{ 'absolute top-0 right-0 m-4': !isPanel }, classClose, brandClasses[type]?.close]"
        data-test-id="vf-dialog-close"
        @click="emit('close')"
      >
        <vf-icon name="close" :size="closeIconSize" />
      </base-dialog-close>
    </header>
    <div
      class="grow"
      :class="[{ 'overflow-y-auto p-4 lg:p-6': isPanel }, brandClasses[type]?.wrapper, classWrapper]"
    >
      <slot />
    </div>
    <footer v-if="$slots.footer" :class="{ 'b-t b-grey-70 p-4 lg:px-6': isPanel }">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'
import { InjectKeyDialog } from '#core/utils/createDialog'

defineProps<{
  /**
   * Classes to be applied to close button.
   */
  classClose?: CSSClass
  /**
   * Classes to be applied to the main content wrapper.
   * Note: this prop can't be named classContent because
   * this name is already in use in the parent component scope
   * that is automatically inherited by this component
   */
  classWrapper?: CSSClass
  hideCloseButton?: boolean
  hideHeader?: boolean
}>()

const emit = defineEmits<{
  close: []
}>()

defineSlots<{
  back: void
  default: void
  footer: void
  title: void
}>()

const { type } = inject(InjectKeyDialog)!
const { brandClasses, closeIconSize } = useAppConfig().components.vf.dialog
const isPanel = type.value === 'panel'
</script>
