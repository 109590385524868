<template>
  <base-radio
    v-bind="{ value, name, disabled }"
    v-model="modelValue"
    class="items-center gap-x-4 align-top i-flex "
    :class="{ 'cursor-pointer': !disabled }"
    data-test-id="vf-radio"
    @change="$emit('change', $event)"
  >
    <span
      class="h-6 w-6 shrink-0 rounded-full p-1 ring ring-inset duration peer-focus-visible:outline-auto "
      :class="[
        variant ? 'peer-focus-within:ring-grey-90' : 'peer-focus-within:ring-grey-10',
        disabled ? (variant ? 'bg-grey-20 ring-grey-50' : 'bg-grey-90 ring-grey-50')
        : invalid ? (variant ? 'ring-red-60' : 'ring-red-30')
          : variant ? 'ring-grey-60' : 'ring-grey-40',
      ]"
      data-test-id="vf-radio-input"
    >
      <span
        class="block full rounded-full transition-opacity"
        :class="[
          modelValue === value && disabled ? 'bg-grey-50'
          : disabled ? 'bg-grey-50' : `bg-brand-1 ${
            variant ? '' : ''
          }`,
          modelValue === value ? 'op-100 ' : 'op-0',
        ]"
      />
    </span>
    <span
      v-if="$slots.default"
      class="w-full transition-colors "
      :class="[
        disabled ? (variant ? 'c-grey-60' : 'c-grey-30')
        : variant ? 'c-white' : 'c-grey-10',
      ]"
      data-test-id="vf-radio-label"
    >
      <!-- @slot Default slot mostly meant for label -->
      <slot />
    </span>
  </base-radio>
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Equal to native 'value' attribute of input:radio
   */
  value: string
  /**
   * Equal to native 'name' attribute of input:radio
   */
  name?: string
  /**
   * Defines disabled state. Means radio button becomes inactive
   */
  disabled?: boolean
  /**
   * Defines invalid state
   */
  invalid?: boolean
  /**
   * Defines variant
   */
  variant?: 'inverse'
}>()

defineEmits<{ change: [value: string] }>()

const modelValue = defineModel<string>()
</script>
