<template>
  <label
    class="relative grid b b-grey-40 rounded-sm bg-white px-3 py-1 focus-within:b-grey-10 [&>*:first-child]:focus-within:c-grey-10"
    :class="[{ 'bg-grey-90 b-grey-50 placeholder-grey-30': disabled }, invalid && (variant === 'inverse' ? 'b-red-60' : 'b-red-30')]"
    data-test-id="vf-textarea"
  >
    <span
      v-if="$slots.default"
      class="text-xs c-grey-20"
      :class="{ 'c-grey-30': disabled, 'c-red-30': invalid }"
      data-test-id="vf-textarea-label"
    >
      <!-- @slot Slot is meant for `label` only -->
      <slot />
      <span v-if="required" aria-hidden="true"> *</span>
    </span>
    <slot name="textarea">
      <base-textarea
        v-bind="{ ...propsTextarea, disabled, required }"
        class="bg-transparent c-grey-10 placeholder-grey-20"
        :class="{
          'pr-10': invalid !== undefined || $slots.end,
          'pr-18': invalid !== undefined && $slots.end,
          'resize-y': resizable,
        }"
        :model-value="modelValue"
        style="min-height: 4.5rem"
        @update:model-value="modelValue = $event"
      />
    </slot>
    <span
      class="absolute right-0 top-0 mr-3 mt-3 flex gap-1"
      data-test-id="vf-textarea-info"
      @click.prevent
    >
      <!-- With click.prevent input gets focused instead of tooltip displaying when user clicks the icon -->
      <slot name="end" />
      <vf-icon
        v-if="invalid !== undefined"
        :class="invalid ? 'c-red-30' : 'c-green-30'"
        :name="invalid ? 'error' : 'success'"
        size="md"
      />
    </span>
  </label>
</template>

<script lang="ts" setup>
import type { TextareaHTMLAttributes } from 'vue'

withDefaults(
  defineProps<{
    /**
     * Defines disabled state
     */
    disabled?: boolean
    /**
     * Defines validation state
     * There are 3 possible states
     * Indeterminate === undefined (no validation icons visible)
     * Valid === true
     * Invalid === false
     */
    invalid?: boolean
    /**
     * Allows to pass properties to textarea native element
     */
    propsTextarea?: TextareaHTMLAttributes
    /**
     * Defines whether textarea can be resized
     */
    resizable?: boolean
    /**
     * Defines if the textarea is required for accessibility purposes
     */
    required?: boolean
    /**
     * Defines variant
     */
    variant?: 'inverse'
  }>(),
  {
    /**
     * Needs to be explicitly set to `undefined` for indeterminate validation state
     */
    invalid: undefined
  }
)

const modelValue = defineModel<string>()
</script>
